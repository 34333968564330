import { create } from "zustand";

export const useFilteredStates = create<{
    filteredStates: string[];
    setFilter: (arraylist: string[]) => void;
  }>()((set) => ({
    filteredStates: [],
    setFilter(arrayList) {
      set(() => ({ filteredStates: arrayList }));
    },
  }));
