import { useEffect } from "react";
import { DateTime } from "luxon";
import { DateRange, DateTimeRangePicker } from "@mui/x-date-pickers-pro";
import { EventOutlined } from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { useTimeSelection } from "@/store/useTimeSelection";
import { ONE_DAY } from "@/utils/durationsInMilliseconds";
import { shortcutsItems, ShortCutsRange } from "./range-shortcuts";

export const CustomDateTimeRangePicker = ({
  initialDates,
  maxDays,
  unavailableShortcuts,
}: {
  initialDates?: DateRange<DateTime>;
  maxDays?: number;
  unavailableShortcuts?: ShortCutsRange[];
}) => {
  const translate = useTranslate();
  const { dates } = useTimeSelection();

  useEffect(() => {
    if (initialDates) {
      const [start, end] = initialDates;

      if (!start || !end) return;

      useTimeSelection.setState((prevState) => ({
        ...prevState,
        dates: {
          dateStart: start,
          dateEnd: end,
        },
      }));
    }

    if (maxDays) {
      const currentRange =
        dates.dateEnd.toMillis() - dates.dateStart.toMillis();
      const maxRange = maxDays * ONE_DAY;
      //? controllo se la differenza tra dateEnd e dateStart
      //? è maggiore rispetto a ONE_DAY * maxDays e reimposto dateEnd al max
      if (currentRange > maxRange) {
        useTimeSelection.setState((prevState) => ({
          ...prevState,
          dates: {
            ...prevState.dates,
            dateEnd: dates.dateStart.plus(maxRange),
          },
        }));
      }
    }
  }, [maxDays, dates.dateEnd, dates.dateStart, initialDates]);

  const onChangeDateRange = (newValue: DateRange<DateTime>) => {
    const [newStart, newEnd] = newValue;
    if (!newStart || !newEnd) return;
    if (newStart.toMillis() === dates.dateStart.toMillis()) {
      // cambiato end
      if (maxDays) {
        const newRange = newEnd.toMillis() - newStart.toMillis();
        const maxRange = maxDays * ONE_DAY;
        //? controllo se la differenza tra nuovo valore e dateStart
        //? è maggiore rispetto a ONE_DAY * maxDays e reimposto dateStart al max
        if (newRange > maxRange) {
          useTimeSelection.setState((prevState) => ({
            ...prevState,
            dates: {
              dateStart: newEnd.minus(maxRange),
              dateEnd: newEnd,
            },
          }));
        } else {
          useTimeSelection.setState((prevState) => ({
            ...prevState,
            dates: {
              ...prevState.dates,
              dateEnd: newEnd,
            },
          }));
        }
      } else {
        useTimeSelection.setState((prevState) => ({
          ...prevState,
          dates: {
            ...prevState.dates,
            dateEnd: newEnd,
          },
        }));
      }
    } else if (newEnd.toMillis() === dates.dateEnd.toMillis()) {
      // cambiato start
      if (maxDays) {
        const newRange = newEnd.toMillis() - newStart.toMillis();
        const maxRange = maxDays * ONE_DAY;
        //? controllo se la differenza tra dateEnd e nuovo valore
        //? è maggiore rispetto a ONE_DAY * maxDays e reimposto dateEnd al max
        if (newRange > maxRange) {
          useTimeSelection.setState((prevState) => ({
            ...prevState,
            dates: {
              dateStart: newStart,
              dateEnd: newStart.plus(maxRange),
            },
          }));
        } else {
          useTimeSelection.setState((prevState) => ({
            ...prevState,
            dates: {
              ...prevState.dates,
              dateStart: newStart,
            },
          }));
        }
      } else {
        useTimeSelection.setState((prevState) => ({
          ...prevState,
          dates: {
            ...prevState.dates,
            dateStart: newStart,
          },
        }));
      }
    } else {
      // cambiato entrambi
      if (maxDays) {
        const newRange = newEnd.toMillis() - newStart.toMillis();
        const maxRange = maxDays * ONE_DAY;
        //? controllo se la differenza tra nuovo valore e dateStart
        //? è maggiore rispetto a ONE_DAY * maxDays e reimposto dateStart al max
        if (newRange > maxRange) {
          useTimeSelection.setState((prevState) => ({
            ...prevState,
            dates: {
              dateStart: newEnd.minus(maxRange),
              dateEnd: newEnd,
            },
          }));
        } else {
          useTimeSelection.setState((prevState) => ({
            ...prevState,
            dates: {
              dateStart: newStart,
              dateEnd: newEnd,
            },
          }));
        }
      } else {
        useTimeSelection.setState((prevState) => ({
          ...prevState,
          dates: {
            dateStart: newStart,
            dateEnd: newEnd,
          },
        }));
      }
    }
  };

  return (
    <DateTimeRangePicker
      value={[dates.dateStart, dates.dateEnd]}
      onChange={onChangeDateRange}
      ampm={false}
      disableFuture
      slotProps={{
        shortcuts: {
          items: shortcutsItems(translate, unavailableShortcuts),
        },
        textField: {
          InputProps: { endAdornment: <EventOutlined /> },
          size: "small",
        },
      }}
    />
  );
};
