import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useGetTimeSelectionList } from "../api/useGetTimeSelectionsList";
import { useTimeSelectionList } from "../store/useTimeSelectionList";
import { ColumnComparisonChart } from "./ColumnComparisonChart";
import { colors } from "@/styles/colors";
import { useTranslate } from "@/i18n/config";

export const ColumnComparison = () => {
  const translate = useTranslate();
  const [isEnabled, setIsEnabled] = useState(false);
  const { timeSelections, setTimeSelections } = useTimeSelectionList();
  useEffect(() => {
    setIsEnabled(true);
    return () => {
      setTimeSelections([]);
    };
  }, []);

  const {
    data: list,
    isLoading,
    error,
  } = useGetTimeSelectionList({ isEnabled });
  const [timeFrames, setTimeFrames] = useState<string[]>([]);

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!list) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, checked },
    } = event;
    console.log(value);
    if (checked) {
      setTimeFrames([...timeFrames, value]);
    } else {
      setTimeFrames(timeFrames.filter((item) => item !== value));
    }
  };

  const handleItemChange = (id: number) => {
    const selectedValue = list?.find((item) => item.id === id);
    if (!selectedValue) return;

    //? update array nello store zustand
    const formattedSelectedValue = {
      name: selectedValue.timelapse,
      dateStart: selectedValue.dateStart,
      dateEnd: selectedValue.dateEnd,
    };

    const isInStore = timeSelections.find(
      (item) =>
        item.name === formattedSelectedValue.name &&
        item.dateStart === formattedSelectedValue.dateStart &&
        item.dateEnd === formattedSelectedValue.dateEnd,
    );
    if (isInStore) {
      setTimeSelections(
        timeSelections.filter(
          (item) =>
            item.name !== formattedSelectedValue.name ||
            item.dateStart !== formattedSelectedValue.dateStart ||
            item.dateEnd !== formattedSelectedValue.dateEnd,
        ),
      );
    } else {
      setTimeSelections([...timeSelections, formattedSelectedValue]);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <List
        subheader={<ListSubheader>{translate("time_frames")}:</ListSubheader>}
        sx={{
          mr: 1,
          width: 400,
          height: 450,
          overflowY: "auto",
          backgroundColor: colors.palette.darkBlue,
          color: "#fff",
          backgroundImage: "none",
          padding: "1rem",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: ".25rem",
        }}
      >
        {list?.map((timeFrame) => (
          <ListItem key={timeFrame.id} disablePadding>
            <FormControlLabel
              control={
                <Checkbox
                  checked={timeFrames.includes(timeFrame.id.toString())}
                  onChange={handleChange}
                  value={timeFrame.id.toString()}
                  onClick={() => handleItemChange(timeFrame.id)}
                />
              }
              label={<ListItemText primary={timeFrame.timelapse} />}
            />
          </ListItem>
        ))}
      </List>
      <Card>
        <ColumnComparisonChart />
      </Card>
    </Box>
  );
};
