import { Icon, Tooltip, styled } from "@mui/material";
import { SyncProblem } from "@mui/icons-material";
import { useCheckAnomalies } from "@/hooks/useCheckAnomalies";
import { useTranslate } from "@/i18n/config";

const Keyframes = styled("div")({
  "@keyframes pulsate": {
    "0%": {
      opacity: 0.5,
      transform: "scale(1)",
    },
    "33%": {
      opacity: 0.9,
      transform: "scale(1.2)",
    },
    "100%": {
      opacity: 1,
    },
  },
  animation: "pulsate 1.2s infinite ease",
});

const RotatingIconWrapper = styled("div")({
  display: "inline-block",
  transition: "transform 0.5s ease", // Transizione fluida per la rotazione
  "&:hover": {
    transform: "rotate(360deg)", // Rotazione di 360° al passaggio del mouse
  },
});

export function AnomalyIcon() {
  const { data, error, isLoading } = useCheckAnomalies();
  const translate = useTranslate();

  if (error || isLoading || !data || !data.response) return null;

  return data.hasAnomalies ? (
    <Tooltip title={translate("anomalies.disclaimer")} placement="bottom" arrow>
      <Keyframes>
        <RotatingIconWrapper>
          <Icon>
            <SyncProblem />
          </Icon>
        </RotatingIconWrapper>
      </Keyframes>
    </Tooltip>
  ) : null;
}
