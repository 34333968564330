import { useFirebaseContext } from "@/context/firebase-context";
import { useTimeSelection } from "@/store/useTimeSelection";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { REPORT_AUTH_TOKEN, REPORT_ENDPOINT } from "./report-endpoint";

type ReportsListResponse = ThingworxError | ReportsListResult;
const Report = z.object({
  filePath: z.string(),
  type: z.union([z.literal("weekly"), z.literal("yearly"), z.literal("daily")]),
  name: z.string(),
  date: z.string(),
  year: z.string(),
  timestamp: z.union([z.number(), z.string()]),
  entity: z.string(),
  entityType: z.string(),
});
type Report = z.infer<typeof Report>;

const ReportsListResult = z.object({
  filesList: z.array(Report),
  response: z.literal(true),
});
export type ReportsListResult = z.infer<typeof ReportsListResult>;

export const useGetReportsList = ({isEnabled}:{isEnabled: boolean}) => {
  const { dates } = useTimeSelection();
  const { user } = useFirebaseContext();

  const payload = {
    user: user?.email,
    fileSystemName: "ima-reports",
    dateStart: dates.dateStart.toMillis(),
    dateEnd: dates.dateEnd.toMillis(),
  };

  return useQuery<ReportsListResult>({
    queryKey: ["reports-list", dates],
    queryFn: async () => {
      const res = await fetch(`${REPORT_ENDPOINT}/report-list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${REPORT_AUTH_TOKEN}`,
        },
        body: JSON.stringify(payload),
      });
      const response = (await res.json()) as ReportsListResponse;
      if (!response.response) {
        throw new Error(response.errorString);
      }
      const validatedResponse = ReportsListResult.parse(response);
      return {
        ...validatedResponse,
        filesList: validatedResponse.filesList.map((file, index) => ({
          ...file,
          id: index,
          date: transformDate(file.date),
          entityType: file.entityType.toLocaleUpperCase(),
          downloadFile: file.filePath,
          timestamp: TimeHelpers.parseTimestampToString({
            timestamp: file.timestamp as number,
          }),
        })),
      };

    },
    enabled: isEnabled 
  });
};

function transformDate(value: string) {
  const dateString = value.startsWith("d")
    ? `day-${value.slice(1)}`
    : value.startsWith("w")
    ? `week-${value.slice(1)}`
    : value.startsWith("y")
    ? `year-${value.slice(1)}`
    : value;
  return dateString;
}
