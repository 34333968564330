/**
 * inizializzare in base a localstorage la macchina selezionata
 * ottenere da firebase la lista delle pagine che questa macchina non ha a disposizione (in futuro modificare con una logica additiva anzichè sottrattiva)
 * le informazioni macchina necessarie sono quindi:
 * - nome macchina
 * - tipologia macchina: ciclica, processo
 * - famiglia di analitiche
 * - pagine non disponibili per la macchina -> ottenere direttamente le pagine per costruire la navigazione
 */

import { useQuery } from "@tanstack/react-query";
import { useEffect, useState, useContext, createContext } from "react";
import {
  Page,
  basePages,
  monitoringPages,
} from "../constants/sentinelNavigations";
import { useNavigate } from "react-router";
import * as Sentry from "@sentry/react";

import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "./firebase-context";
import { ThingworxError } from "src/types/error";

type Machine = {
  machine: string;
  machineType: "cyclic" | "process";
  machineLogic: "static" | "parametric";
  hasBatch: boolean;
  hasSpeed: boolean;
};

interface Context {
  machine: Machine | null;
  monitoringPages: Page[];
  basePages: Page[];
  allPages: string[];
  setMachine: (machine: Machine) => void;
  isLoading: boolean;
}

const MachineContext = createContext<Context>({
  machine: null,
  monitoringPages: [],
  basePages: [],
  allPages: [],
  setMachine: () => {},
  isLoading: true,
});

const useGetMachineUnavailablePages = (machine: string) => {
  const { appKey, user } = useFirebaseContext();
  const email = user?.email as string;

  return useQuery<string[]>({
    queryKey: ["machine-info", machine, email],
    queryFn: async () => {
      const response = await FetchClient<
        { machineName: string; account: string },
        ThingworxError | { response: true; pages: string[] }
      >({
        appKey,
        payload: { machineName: machine, account: email },
        url: "dig.c.landingPage_thing/Services/getMachinePages",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return response.pages || [];
    },
    enabled: machine !== "",
  });
};

export const MachineContextProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [machine, setMachine] = useState<Machine | null>(() => {
    const ctx = localStorage.getItem("machine-ctx");
    if (!ctx) return null;
    return JSON.parse(ctx) as Machine;
  });

  const { data: availablePages = [], isLoading } =
    useGetMachineUnavailablePages(machine?.machine || "");

  const bPages = basePages.filter((page) => availablePages.includes(page.name));

  const mPages = monitoringPages.filter((page) =>
    availablePages.includes(page.name),
  );

  // todo: set all machine information othern than the name, like type, family, etc
  const setMachineHandler = (machine: Machine) => {
    Sentry.setTag("machine_name", machine.machine);
    localStorage.setItem("machine-ctx", JSON.stringify(machine));
    setMachine(machine);
  };

  useEffect(() => {
    if (!machine) navigate("/overview/home");
  }, [machine]);

  return (
    <MachineContext.Provider
      value={{
        machine,
        isLoading,
        basePages: bPages,
        allPages: availablePages,
        monitoringPages: mPages,
        setMachine: setMachineHandler,
      }}
    >
      {children}
    </MachineContext.Provider>
  );
};

export const useMachineContext = () => {
  const context = useContext(MachineContext);
  if (!context) {
    throw new Error("useMachineContext must be used within a MachineProvider");
  }
  return context;
};
