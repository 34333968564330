import { AnimatePresence, motion } from "framer-motion";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { InfoOutlined } from "@mui/icons-material";
import { Loader } from "@/components/Layout/Loader";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { useGetLandingData } from "./api/useGetLandingData";
import { ContainerLayout } from "./components/ContainerLayout";
import { Filters } from "./components/Filters";
import { MachineCard } from "./components/MachineCard";
import { StateCategories } from "./components/StateCategories";
import {
  LandingContextProvider,
  useLandingContext,
} from "./context/landing-context";
import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";

export const LandingPage = () => {
  const translate = useTranslate();
  const { data, isFetching } = useGetLandingData();

  if (!data)
    return (
      <>
        <OverviewNavBar>
          <Stack gap={2} direction="row">
            <SelectTimeSelection
              exclude={[
                "Custom",
                "TwoWeeks",
                "Month",
                "ThreeMonths",
                "SixMonths",
                "Year",
              ]}
              isMachinePage={false}
            />
          </Stack>
        </OverviewNavBar>
        <Loader isLoading />
      </>
    );

  return (
    <>
      <LandingContextProvider machines={data.landingPageData.machineArray}>
        <OverviewNavBar>
          <Stack gap={2} direction="row">
            <SelectTimeSelection
              exclude={[
                "Custom",
                "TwoWeeks",
                "Month",
                "ThreeMonths",
                "SixMonths",
                "Year",
              ]}
              isMachinePage={false}
            />
          </Stack>
        </OverviewNavBar>

        <Stack flexDirection="column" gap={2}>
          <Filters />
          <StateCategories />
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: ".5rem",
              padding: ".5rem",
            }}
          >
            <InfoOutlined />
            <Typography variant="caption">
              {translate("landing.disclaimer")}
            </Typography>
          </Card>
          <MachineOverview />
        </Stack>
      </LandingContextProvider>
      {isFetching && (
        <LinearProgress
          sx={{
            position: "fixed",
            width: "80%",
            margin: "auto",
            bottom: 10,
            left: 64,
            right: 0,
            borderRadius: "1000px",
          }}
        />
      )}
    </>
  );
};

function MachineOverview() {
  const [{ filteredMachines, filteringByCategory }] = useLandingContext();
  const translate = useTranslate();

  const { data: landingData } = useGetLandingData();
  const selectedMachinesId = filteredMachines.map((m) => m.machine);

  const machines =
    filteringByCategory !== null
      ? landingData?.landingPageData.machineArray
          .filter((m) => selectedMachinesId.includes(m.machine))
          .filter((m) => m.category === filteringByCategory) ?? []
      : landingData?.landingPageData.machineArray.filter((m) =>
          selectedMachinesId.includes(m.machine),
        ) ?? [];

  return (
    <Stack gap={2} flexDirection="column">
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <ContainerLayout>
          {machines.length > 0 ? (
            <Grid container spacing={2} columns={{ xs: 2, sm: 3, md: 5 }}>
              <AnimatePresence>
                {machines.map((machine) => (
                  <MachineCard key={machine.machine} {...machine} />
                ))}
              </AnimatePresence>
            </Grid>
          ) : (
            <Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              sx={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
              }}
            >
              {translate("no_machines_to_display")}
            </Box>
          )}
        </ContainerLayout>
      </motion.div>
    </Stack>
  );
}
